import React from "react"
import { Link } from "gatsby"
import { FaUserCircle, FaPen, FaFile, FaPencilAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = () => (
  <Layout>
    <SEO title="Our Services" />

    <h1>Candeo Landscape Design Services</h1>

    <h2>
      <FaUserCircle /> Initial Consultation
    </h2>

    <p>
      A consultation of approximately one hour will be conducted at your
      property. The design team will discuss your garden preferences and budget
      to gather adequate information to develop your ideal garden. The cost for
      the consultation starts at $200 and can vary depending on location of your
      property, this will be discussed at the time of booking.
    </p>

    <p>
      This price allows for clients in suburban Melbourne only. Rural,
      interstate and Overseas clients will be priced individually upon request.
    </p>

    <h2>
      <FaFile /> Fee Proposal
    </h2>

    <p>
      Client will receive a fee proposal for the design works for their garden
      as discussed during the initial consultation, the Fee Proposal will cover
      all aspects of the design phase of the garden project.
      <br />
    </p>

    <p>
      At Candeo Design, every garden we design is different. We are passionate
      and flexible about the gardens we design and their individuality. The
      design of every garden is priced the same way as it is designed, on it's
      individual merits.
    </p>

    <h2>
      <FaPencilAlt /> Design Phase
    </h2>

    <p>The Design Phase usually consists of:</p>

    <p>
      <strong>Concept Plan</strong> – Showing the set out of the garden
      including paving areas, lawn areas and garden bed. As well as placement of
      features such as pergolas, garden furniture, steps and walls, water
      features and sculptures. A concept plan is usually hand coloured and is
      presented to the client for discussion being going to the Master Plan
      Phase of the design.
    </p>

    <p>
      <strong>Master Plan</strong> – Goes into the detail of the garden.
      Specifying materials and construction techniques. Showing heights and
      levels, Plant schedule and Planting plan, Details and elevations (where
      required), Quantities of materials and approved suppliers. This plan is
      the finished working plan showing all information for the contractor to
      work from.
    </p>

    <p>
      <strong>Planting Design</strong> - A consultation of approximately one
      hour will be conducted at your property. The design team will discuss your
      garden preferences and budget to gather adequate information to develop
      your ideal garden. The cost for the consultation starts at $200 and can
      vary depending on location of your property, this will be discussed at the
      time of booking.
    </p>

    <h2>Project Management</h2>

    <p>
      Project Management is available by request. Project Management is priced
      on each projects individual requirement and the client's wishes.
    </p>

    <h2>Horticulture Advisories</h2>

    <p>
      Candeo Design is a horticultural based design company where beautiful
      gardens are our passion. If you are more of a hands on type of person and
      would like some garden advice Candeo Design is happy to help. 1 hour
      meeting in your garden to discuss your garden requirements and advise the
      best ways to help your garden "Grow Brilliant". $220.00 inc. GST. (This
      price allows for clients in suburban Melbourne only. Rural, interstate and
      Overseas clients will be priced individually upon request.) Advisories can
      be longer than 1 hour upon request.
    </p>

    <h2>General Inquiries</h2>

    <p>
      All gardens are different. At Candeo Design we understand this and we are
      happy to discuss any issues you may have in or around the garden and
      hopefully provide and solution for your particular situation. If you are
      not sure what category you fall under? You may not fall under one? Contact
      us and we will be happy to discuss and point you in the right direction.
    </p>
  </Layout>
)
export default ServicesPage
